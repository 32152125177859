import React from "react";
import {push as Menu} from "react-burger-menu";
import {Link} from "react-scroll";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

export const navItems = [
    {href: 'about', duration: 500,},
    {href: 'features', duration: 700,},
    {href: 'product', duration: 900,},
    {href: 'tech', duration: 1100,},
    {href: 'contact', duration: 1300,},
]

const SideBarStyled = styled.div`
  position: relative;

  a {
    cursor: pointer;

    &:last-child {
      border-top: 1px solid rgba(141, 141, 141, 0.53);
      margin-top: 20px;
      padding-top: 20px;
    }
  }

`

export default props => {
    const {t, i18n} = useTranslation('common')

    return <SideBarStyled>
        <Menu {...props} right push>

            {navItems.map((el, index) => <Link
                key={el.duration}
                to={el.href}
                spy={true}
                smooth={true}
                duration={el.duration}
            >
                {t(`nav.${index}`)}
            </Link>)}

            <a
                style={{cursor: "pointer"}}
                onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}
            >
                {i18n.language === 'ru' ? 'EN' : 'RU'}
            </a>
        </Menu>
    </SideBarStyled>
}
