import React, {useState} from 'react';
import {Container} from "./ui/Container";
import styled from "styled-components";
import FsLightbox from 'fslightbox-react';
import { Navigation } from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import medium1 from "./../img/products/medium1.png"
import medium2 from "./../img/products/medium2.png"
import medium3 from "./../img/products/medium3.png"
import medium4 from "./../img/products/medium4.png"
import medium5 from "./../img/products/medium5.png"
import medium6 from "./../img/products/medium6.png"
import medium7 from "./../img/products/medium7.png"
import medium8 from "./../img/products/medium8.png"
import medium9 from "./../img/products/medium9.png"
import medium10 from "./../img/products/medium10.png"
import medium11 from "./../img/products/medium11.png"
import medium12 from "./../img/products/medium12.png"
import medium13 from "./../img/products/medium13.png"
import medium14 from "./../img/products/medium14.png"
import medium15 from "./../img/products/medium15.png"
import medium16 from "./../img/products/medium16.png"
import medium17 from "./../img/products/medium17.png"
import medium18 from "./../img/products/medium18.png"
import medium19 from "./../img/products/medium19.png"
import medium20 from "./../img/products/medium20.png"
import medium21 from "./../img/products/medium21.png"
import big1 from "./../img/products/big1.png"
import big2 from "./../img/products/big2.png"
import big3 from "./../img/products/big3.png"
import big4 from "./../img/products/big4.png"
import big5 from "./../img/products/big5.png"
import big6 from "./../img/products/big6.png"
import big7 from "./../img/products/big7.png"
import big8 from "./../img/products/big8.png"
import big9 from "./../img/products/big9.png"
import big10 from "./../img/products/big10.png"
import big11 from "./../img/products/big11.png"
import big12 from "./../img/products/big12.png"
import big13 from "./../img/products/big13.png"
import big14 from "./../img/products/big14.png"
import big15 from "./../img/products/big15.png"
import big16 from "./../img/products/big16.png"
import big17 from "./../img/products/big17.png"
import big18 from "./../img/products/big18.png"
import big19 from "./../img/products/big19.png"
import big20 from "./../img/products/big20.png"
import big21 from "./../img/products/big21.png"
import {useTranslation} from "react-i18next";
import "swiper/css/navigation";
import "swiper/css/bundle";

const ProductsStyled = styled.div`
  background: #F5F5F3;
  > div {
    padding-bottom: 110px;
    
    
    > h1 {
      margin-bottom: 75px;
      text-align: center;
    }
    
    
    .swiper {
      padding: 0 60px;
    }
    .swiper-button-prev,.swiper-button-next {
      color: #333333;
    }
    .swiper-slide {
      min-height: 125px;
    }
    
  }

  @media (max-width: 566px) {
    > div {
      padding-bottom: 60px;
      > h1 {
        margin-bottom: 40px;
      }
    }
  }
`

const images = [
    medium1,
    medium2,
    medium3,
    medium4,
    medium5,
    medium6,
    medium7,
    medium8,
    medium9,
    medium10,
    medium11,
    medium12,
    medium13,
    medium14,
    medium15,
    medium16,
    medium17,
    medium18,
    medium19,
    medium20,
    medium21,
]


const big = [
    big1,
    big2,
    big3,
    big4,
    big5,
    big6,
    big7,
    big8,
    big9,
    big10,
    big11,
    big12,
    big13,
    big14,
    big15,
    big16,
    big17,
    big18,
    big19,
    big20,
    big21,
]


const Products = () => {
    const [box, setBox] = useState(false);
    const [slide, setSlide] = useState(0)
    const {t} = useTranslation('common')


    return <>
        <ProductsStyled>
            <Container>
                <h1 data-aos="fade-up">{t("product")}</h1>
                <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    spaceBetween={65}
                    slidesPerView={4}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        566: {
                            slidesPerView: 2,
                        },
                        766: {
                            slidesPerView: 3,
                        },
                        966: {
                            slidesPerView: 4,
                        }
                    }}
                >
                    {images.map((img, i) => <SwiperSlide
                        key={i}
                        onClick={() => {
                            setBox(prev => !prev)
                            setSlide(i)
                        }}
                        style={{background: `url(${img}) no-repeat center center / contain`}}/>)}
                </Swiper>
            </Container>
        </ProductsStyled>
        <FsLightbox
            toggler={box}
            sources={big}
            sourceIndex={slide}
            key={big.length}
        />
    </>
}

export default Products;
