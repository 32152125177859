import React from 'react';
import styled from "styled-components"
import {Container} from "./ui/Container";
import aboutImg from "./../img/main-logo2.png"
import variable from "./../style/var.module.scss"
import Fade from "react-reveal/Fade";
import {useTranslation} from "react-i18next";

export const AboutStyled = styled.div`
  background: #FFFFFF;

  > div {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 120px;

    > div {
      > h1 {
        padding-bottom: 10px;
      }

      > p {
        margin-top: 20px;
        color: #39393A;
      }
    }

    > span {
      display: block;
      width: 343px;
      height: 471px;
      background: url(${aboutImg}) no-repeat center center / cover;
    }

  }

  @media (max-width: 1073px) {
    > div {
      grid-gap: 40px;
    }
  }

  @media (max-width: 935px) {
    > div {
      > span {
        margin-top: 95px;
      }
    }
  }
  @media (max-width: 791px) {
    > div {
      grid-template-columns: 1fr;

      > span {
        margin-top: auto;
        width: 100%;
        height: 200px;
        background-position: center bottom;
      }
    }
  }

  @media (max-width: 566px) {
    > div {
      grid-template-columns: 1fr;

      > div {
        > h1 {
          padding-bottom: 0;
        }
      }
    }
  }
`


const About = () => {
    const {t} = useTranslation('common')

    return <AboutStyled>
        <Container top>
            <div>
                <h1 data-aos="fade-up">{t("aboutUs.name")}</h1>
                <p data-aos="zoom-in-up" data-aos-delay="100">{t("aboutUs.desc.1")}</p>
                <p data-aos="zoom-in-up" data-aos-delay="200">{t("aboutUs.desc.2")}</p>
                <p data-aos="zoom-in-up" data-aos-delay="300">{t("aboutUs.desc.3")}</p>
            </div>
            <span data-aos="zoom-in"/>
        </Container>
    </AboutStyled>
}

export default About;
