import React, {useState} from 'react';
import styled from "styled-components"
import {Container} from "./ui/Container";
import info1 from "./../img/Frame-tech.png"
import info2 from "./../img/Frame-tech1.png"
import variable from "./../style/var.module.scss"
import {useTranslation} from "react-i18next";

const InfoStyled = styled.div`
  > div {
    > span {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 15px;
      margin-top: 30px;

      > button {
        border: none;
        padding: 10px 18px;
        border-radius: 100px;
        font-size: 16px;
        cursor: pointer;
        background: #F5F5F3;
      }

      > .activeButton {
        background: ${variable.MAIN_COLOR};
        color: ${variable.BG_COLOR};
      }
    }

    > div {
      margin-top: 60px;
      display: flex;
      align-items: flex-start;
      grid-gap: 40px;


      > img {
        width: 300px;
      }


      > span {
        display: block;

        > p {
          margin-top: 20px;

          &:first-child {
            padding-bottom: 10px;
            font-weight: 600;
            margin-top: 0;
          }
        }
      }


    }

    @media (max-width: 766px) {
      > div {
        display: block;

        > span {
          margin-top: 20px;
        }

        > img {
          width: 250px;
        }
      }
    }

    @media (max-width: 566px) {
      > div {
        margin-top: 40px;

        > img {
          width: 175px;
        }
      }

    }
  }
`


const lists = [
    {
        id: 1,
        img: info1,
        desc: ["", "", ""]
    },
    {
        id: 2,
        img: info2,
        desc: ["", "", ""]
    },
    {
        id: 3,
        img: "",
        desc: [""]
    }
]


const Info = () => {
    const [idx, setIdx] = useState(0)
    const {t} = useTranslation('common')


    return <InfoStyled>
        <Container top>
            <h1 data-aos="fade-up">{t(`info.${idx}.name`)}</h1>
            <span data-aos="fade-up">
                {["", "", ""].map((b, i) => <button
                    key={i}
                    className={i === idx ? "activeButton" : ""}
                    onClick={() => setIdx(i)}
                >{t(`info.${i}.name`)}</button>)}
            </span>
            {lists.filter((_, i) => i === idx).map((el, id) => <div key={id}>
                {el.img && <img src={el.img} alt={el.name}/>}
                <span>
                    {el.desc.map((list, idxx) => <p
                        data-aos="zoom-in-up"
                        key={idxx}
                        data-aos-delay={`${idxx + 1}00`}>{t(`info.${idx}.desc.${idxx}`)}</p>)}
                </span>
            </div>)}
        </Container>
    </InfoStyled>
}

export default Info;
