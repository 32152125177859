import React from 'react';
import {Container} from "./ui/Container";
import styled from "styled-components"
import {useTranslation} from "react-i18next";
import footerImage from '../img/footer.png';
import {Button} from "./ui/Button";

const FooterStyled = styled.div`

  > footer {
    position: relative;
    background: url(${footerImage}) no-repeat center center / cover;

    > div {
      position: relative;

      > p {
        color: #fff;
        max-width: 390px;
      }

      > form {
        margin-top: 60px;
        max-width: 417px;

        > input, > textarea {
          display: block;
          background: none;
          border: none;
          border-bottom: 1px solid #fff;
          padding-bottom: 10px;
          padding-left: 10px;
          width: 100%;
          font-size: 18px;
          outline: none;
          margin-top: 28px;
          color: #fff;

          &::placeholder {
            color: #fff;
          }
        }

        > textarea {
          height: 150px;
          resize: none;
        }

        > button {
          margin-top: 71px;

          @media(max-width: 566px) {
            margin-top: 40px;
          }
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.57);
    }
  }


  > div {
    background: #000000;
    padding: 26px 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      grid-gap: 40px;

      > a {
        text-decoration: none;
        color: #F8F8F8;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;

        &:nth-child(2) {
          max-width: 320px;
        }
      }
    }
    
    
    @media(max-width: 600px) {
      > div {
        display: block;
        > a {
          display: block;
          text-align: center;
          max-width: 100% !important;
          padding: 10px 0;
        }
      }
    }
  }


`


const Footer = () => {
    const {t} = useTranslation('common')

    return <FooterStyled>
        <footer>
            <Container top>
                <p data-aos="fade-up">{t`footer.desc`}</p>
                <form>
                    <input data-aos="zoom-in-up" data-aos-delay="100" type="text" placeholder={t`footer.fullName`}/>
                    <input data-aos="zoom-in-up" data-aos-delay="200" type="text" placeholder={t`footer.phoneNumber`}/>
                    <textarea data-aos="zoom-in-up" data-aos-delay="300" placeholder={t`footer.message`}/>
                    <Button data-aos="zoom-in-up" data-aos-delay="400">{t`submitButton`}</Button>
                </form>
            </Container>
        </footer>
        <div>
            <Container>
                <a href="mailto:bread@makolli.tj">bread@makolli.tj</a>
                <a>{t`local`}</a>
                <a href="tel:900303030">(+992) 900-30-30-30</a>
            </Container>
        </div>
    </FooterStyled>
}

export default Footer;
