import React from "react"
import styled, {css} from "styled-components";
import variable from "./../../style/var.module.scss"


const ButtonStyled = styled.button`
  padding: 13px 18px;
  font-size: 16px;
  background: ${variable.MAIN_COLOR};
  color: ${variable.BG_COLOR};
  cursor: pointer;
  width: ${props => props.width ? `${props.width}px` : '223px'};
  border-radius: 34px;
  border: none;

  &:hover {
    background: #f31b23;
    box-shadow: 0 0 10px ${variable.MAIN_COLOR};
  }
  
`


export const Button = ({children, ...rest}) => <ButtonStyled {...rest}>{children}</ButtonStyled>
