import React, {useEffect} from 'react';
import SideBar from "./components/SideBar";
import Header from "./components/Header";
import SwiperCore, {Navigation} from "swiper";
import About from "./components/About";
import Info from "./components/Info";
import Products from "./components/Products";
import Footer from "./components/Footer";
import {Element} from 'react-scroll'
import Features from "./components/Features";
import AOS from "aos"
import 'aos/dist/aos.css';
import 'swiper/css';

SwiperCore.use([Navigation])

const App = () => {
    useEffect(() => {
        AOS.init({
            duration : 1000,
            once: true
        });
    }, []);

    return <div id="App">

        <SideBar push pageWrapId={"page-wrap"} outerContainerId={"App"}/>

        <div id="page-wrap" style={{overflow: "hidden"}}>
            <Header/>
            <Element name="about">
                <About/>
            </Element>
            <Element name="features">
                <Features/>
            </Element>
            <Element name="product">
                <Products/>
            </Element>
            <Element name="tech">
                <Info/>
            </Element>
            <Element name="contact">
                <Footer/>
            </Element>
        </div>
    </div>
}

export default App;
