import React from 'react';
import styled from "styled-components";
import {Container} from "./ui/Container";
import feature1 from '../img/feature1.png'
import feature2 from '../img/feature2.png'
import feature3 from '../img/feature3.png'
import feature4 from '../img/feature4.png'
import imgHR from '../img/imgHR.png'
import {useTranslation} from "react-i18next";

const FeaturesStyled = styled.main`
  background: #F5F5F3;

  > div {
    padding-bottom: 60px;

    > h1 {
      text-align: center;
    }

    > section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 42px;
      margin-top: 60px;

      > article {
        display: grid;
        grid-template-rows: 150px auto 1fr;
        grid-gap: 10px;


        > img {
          margin: auto;
        }

        > h2 {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
        }

        > p {
          color: #39393A;
          font-size: 14px;
          text-align: center;
        }
      }
    }

    > img {
      display: flex;
      margin: 60px auto 0;
    }
  }

  @media (max-width: 1053px) {
    > div {
      > section {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      > img {
        width: 100%;
      }
    }
  }

  @media (max-width: 566px) {
    > div {
      > section {
        grid-template-columns: 1fr;
        margin-top: 40px;
        grid-gap: 20px;
      }
    }
  }
  
`
const features = [
    {id: 1, img: feature1},
    {id: 2, img: feature2},
    {id: 3, img: feature3},
    {id: 4, img: feature4}
]

const Features = () => {
    const {t} = useTranslation('common')

    return <FeaturesStyled>
        <Container top>
            <h1 data-aos="fade-up">{t`quality.title`}</h1>
            <section>
                {features.map((item, id) => <article key={item.id} data-aos="zoom-in-up" data-aos-delay={`${id + 1}00`}>
                    <img src={item.img} alt={item.title}/>
                    <h2>{t(`quality.features.${id}.title`)}</h2>
                    <p>{item.desc} {t(`quality.features.${id}.desc`)}</p>
                </article>)}
            </section>
            <img src={imgHR} alt="img"/>
        </Container>
    </FeaturesStyled>
}

export default Features;
