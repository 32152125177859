import React from 'react';
import styled, {css} from "styled-components";

const ContainerStyled = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 566px) {
    padding: 0 20px;
  }

  ${props => props.top && css`
    padding-top: 80px;
    padding-bottom: 80px;

    @media (max-width: 566px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  `}

`


export const Container = ({children, ...rest}) => <ContainerStyled {...rest}>
    {children}
</ContainerStyled>
