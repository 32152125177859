import React from 'react';
import {Container} from "./ui/Container";
import {Button} from "./ui/Button";
import {BsInstagram} from "react-icons/bs";
import {SiFacebook, SiTelegram} from "react-icons/si";
import styled from "styled-components";
import logo from "./../img/logo.png";
import mainLogo from '../img/main-logo.png'
import {useTranslation} from "react-i18next";
import {scroller} from "react-scroll";

const HeaderStyled = styled.div`
  background: url(${mainLogo}) no-repeat center center / cover;
  padding-bottom: 42px;
  position: relative;

  > div {

    > img {
      margin-top: 46px;
      margin-left: -124px;
    }

    > h1 {
      color: #fff;
      margin-top: 84px;
      max-width: 450px;
    }

    > p {
      margin-top: 30px;
      color: #DEDEDE;
      max-width: 481px;
    }

    > button {
      margin-top: 60px;
    }

    > span {
      display: flex;
      align-items: center;
      grid-gap: 26px;
      width: 143px;
      margin-left: -123px;
      margin-top: 100px;

      > svg {
        color: #fff;
        font-size: 30px;

        > path {
          color: #fff;
        }
      }
    }
  }


  @media (max-width: 1412px) {
    > div {
      > img {
        margin-left: 0;
      }

      > span {
        margin-left: 0;
      }
    }
  }


  @media (max-width: 566px) {

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(49, 49, 49, 0.64) 10%, transparent 20%);
    }


    > div {
      position: relative;
      z-index: 1;

      > img {
        margin-top: 30px;
        height: 50px;
      }

      > h1 {
        margin-top: 60px;
      }

      > p {
        margin-top: 20px;
      }

      > button {
        margin-top: 40px;
      }

      > span {
        margin-top: 60px;
      }
    }
  }

`

const Header = () => {
    const {t} = useTranslation('common')


    const scrollTo = () => {
        scroller.scrollTo("contact", {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
        })
    }


    return <HeaderStyled>
        <Container>

            <img data-aos="zoom-out-up" src={logo} alt="logo"/>

            <h1 data-aos="fade-up">{t`main.title`}</h1>
            <p data-aos="zoom-in-up">{t`main.desc.1`} {t`main.desc.2`} {t`main.desc.3`} {t`main.desc.4`}</p>
            <Button onClick={scrollTo} data-aos="zoom-out-up">{t`submitButton`}</Button>

            <span data-aos="zoom-out-up">
                <SiFacebook/>
                <BsInstagram/>
                <SiTelegram/>
            </span>

        </Container>
    </HeaderStyled>
}

export default Header
